import React, { Component } from 'react';
import { Link } from 'gatsby';
import siteConfig from "data/SiteConfig";
import Slider from "react-slick";

const moment = require('moment');

class SectionMiddle extends Component {

    state = {
        news_banner : null
    }

    changeTab = (type) => {
        this.props.actions.change_news_tab(type);
    }

    populateArticle = (data) => {
        let list;
        if (data) {
            list = data.edges.map( (nodes, index) => 
                <li key={index} className="tab-content__container__content">
                    <span className={"tag " + ((nodes.node.type === "1") ? "update" : "event")}>
                        {(nodes.node.type === "1") ? "Update" : "Event"}
                    </span>
                    <Link to={`/${this.props.pageContext.lang.toLowerCase()}/post/${nodes.node.slug}`} className="title">
                        {nodes.node.title}
                    </Link>
                    <span className="date">{moment(nodes.node.date_created).format("DD/MM/YY")}</span>
                </li>
            )
        }
        return list
    }

    generateTab = (lang, home) => {
        const newsTab = siteConfig.newsTab;
        return newsTab.map((data,index) => 
            <div key={index} className={"tab-container__tab" + ((home.tab === data) ? ' active' : '')} onClick={() => this.changeTab(data)}>
                <span>{siteConfig.translate[lang].news[index]}</span>
            </div>
        )
    }

    generateTabContainer = (lang, home, data) => {
        const newsTab = siteConfig.newsTab;
        return newsTab.map((news,index) => 
            <ul key={index} className={"tab-content__container" + ((home.tab === news) ? ' active' : '') }>
                {this.populateArticle(data[news])}
                <li className="tab-content__container__content see_all" >
                    <Link to={`/${lang}/news/${news}`}>{(lang === "en") ? "See More >>" : "Lihat Semua >>"}</Link>
                </li>
            </ul>
        )
    }
    
    handleRedirect = (link) => {
        window.location.href = link
    }

    generateSliderImage = () => {
        const { data } = this.props;
        let banner = data.banner_news;

        let banner_list = banner.edges.map((edge, index) => {
            return <div key={index} onClick={()=> this.handleRedirect(edge.node.link)} style={{ "cursor" : "pointer" }} className="banner_list_click">
                <img src={edge.node.image} alt="" />
            </div>
        })

        return banner_list;
    }

    componentDidMount() {
        let banner_title = [];

        const { data } = this.props;
        let banner = data.banner_news;
        
        banner.edges.forEach(edge => 
            banner_title.push(edge.node.name)
        )

        this.setState({
            news_banner : banner_title
        })
    }

    render() {
        let { home, data } = this.props;
        let lang = this.props.pageContext.lang.toLowerCase();
        const settings = {
            autoplay: true,
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            customPaging: i => (
                <div>{(this.state.news_banner) ? this.state.news_banner[i] : ""}</div>
            )
        }
        return (
            <div className="section section__middle">
                <h1 className="section__title">{siteConfig.translate[lang].home.section_title_1}</h1>
                <div className="content">
                    <div className="swiper-container-top">
                        <Slider {...settings}>
                            {this.generateSliderImage()}
                        </Slider>
                    </div>
                    <div className="news-container">
                        <div className="tab-container">
                            {this.generateTab(lang, home)}
                        </div>
                        <div className="tab-content">
                            {this.generateTabContainer(lang, home, data)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SectionMiddle;