import React, { Component } from 'react';

class SectionTop extends Component {
    render() {
        let {pageContext} = this.props;
        return (
            <div className="section section__top">
                {
                    (pageContext.lang == "ID") ?
                    (<img className="img-fluid" src={require('assets/img/Home/Title_Sekarang-Eranya-Ngedrift.png')} alt="" />) :
                    (<img className="img-fluid" src={require('assets/img/Home/Title_en.png')} alt="" />)
                }
                
                <a onClick={() => this.props.actionsDownload.open_modal(true)} target="_blank">
                    <img src={require('assets/img/Home/Button_Download.png')} alt="" />
                </a>
            </div>
        );
    }
}

export default SectionTop;