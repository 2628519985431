import React, { Component } from 'react';
import siteConfig from "data/SiteConfig";
import Slider from "react-slick";

class SectionBottom extends Component {

	state = {
		feature_banner: null
	}

	componentDidMount() {
        let banner_title = [];

        const { data } = this.props;
        let banner = data.banner_feature;
        
        banner.edges.forEach(edge => 
            banner_title.push(edge.node.image)
        )

        this.setState({
            feature_banner : banner_title
        })
    }

	generateSliderImage = () => {
        const { data } = this.props;
        let banner = data.banner_feature;

        let banner_list = banner.edges.map((edge, index) => {
            return <div key={index}>
                <img src={edge.node.image} alt="" />
            </div>
        })

        return banner_list;
    }

	render() {
		let lang = this.props.pageContext.lang.toLowerCase();
		const settings = {
            autoplay: true,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            customPaging: i => (
				<div>
					<img src={(this.state.feature_banner) ? this.state.feature_banner[i] : ""} alt="" />
				</div>
            )
        }
		return (
			<div className="section section__bottom">
				<h1 className="section__title">{siteConfig.translate[lang].home.section_title_2}</h1>
				<div className="content">
					<Slider {...settings}>
						{this.generateSliderImage()}
					</Slider>
				</div>
			</div>
		);
	}
}

export default SectionBottom;