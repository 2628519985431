import React, { Component } from 'react'
import { graphql } from "gatsby";
import Layout from "../layout";

// Include Swiper CSS
import 'assets/css/swiper.min.css';

// Component 
import SectionTop from 'components/Home/SectionTop';
import SectionMiddle from 'components/Home/SectionMiddle';
import SectionBottom from 'components/Home/SectionBottom';
import Floating from 'components/Home/Floating';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as homeActions from 'redux/actions/home';
import * as downloadActions from 'redux/actions/download';

class Index extends Component {
	render() {
		return (
			<Layout>
				<div id="home">
					<Floating {...this.props} />
					<SectionTop {...this.props} />
					<SectionMiddle {...this.props} />
					<SectionBottom {...this.props} />
				</div>
			</Layout>
		)
	}
}

function mapStateToProps(state) {
	return { 
    home: state.home,
    download: state.download
  }
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(homeActions, dispatch),
		actionsDownload: bindActionCreators(downloadActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);

/* eslint no-undef: "off" */
export const homeQuery = graphql`
query HomeArticle($lang_id: Int!, $lang: String!)
{
  all:  allDirectusArticleList(
    sort: {
      fields: directusId
      order: DESC
    }
    limit: 10
    filter: {
      region_id  : {eq: $lang_id}
    }
  ) {
    edges {
      node {
        title
        slug
        type
        region_id
        date_created
      }
    }
  }
  update: allDirectusArticleList(
    sort: {
      fields: directusId
      order: DESC
    }
    limit: 10
    filter: {
      type : {eq: "1"}
      region_id  : {eq: $lang_id}
    }
  ) {
    edges {
      node {
        type
        title
        slug
        region_id
        date_created
      }
    }
  }
  event: allDirectusArticleList(
    sort: {
      fields: directusId
      order: DESC
    }
    limit: 10
    filter: {
      type : {eq: "2"}
      region_id  : {eq: $lang_id}
    }
  ) {
    edges {
      node {
        type
        title
        slug
        region_id
        date_created
      }
    }
  }
  banner_news: allDirectusNewsSlider(
    sort: {
      fields: sort
      order: ASC
    }
    filter : {
      region_id : {eq:$lang_id}
    }
  ) {
    edges {
      node {
        name
        image
        link
        region_id
        sort
      }
    }
  }
  banner_feature: allDirectusFeatureSlider(
    sort: {
      fields: sort
      order: ASC
    }
    filter : {
      region_id : {eq:$lang_id}
    }
  ) {
    edges {
      node {
        name
        image
        link
        region_id
        sort
      }
    }
  }
  socmed: allDirectusConfigList(filter: {region_id: {eq: $lang}}) {
    edges {
      node {
        facebook
        instagram
        youtube
      }
    }
  }
}
`;