import React, { Component } from 'react';

class Floating extends Component {
    render() {
        return (
            <div id="floating">
                <img src={require("assets/img/Floating/Citrus.png")} alt="" className="icon"/>
                <a href={this.props.data.socmed.edges[0].node.facebook} target="_blank">
                    <img src={require("assets/img/Floating/Facebook.png")} alt="" className="desktop"/>
                    <img src={require("assets/img/Floating/Facebook_mobile.png")} alt="" className="mobile"/>
                </a>
                <a href={this.props.data.socmed.edges[0].node.instagram} target="_blank">
                    <img src={require("assets/img/Floating/Instagram.png")} alt="" className="desktop"/>
                    <img src={require("assets/img/Floating/Instagram_mobile.png")} alt="" className="mobile"/>                    
                </a>
                <a href={this.props.data.socmed.edges[0].node.youtube} target="_blank">
                    <img src={require("assets/img/Floating/Youtube.png")} alt="" className="desktop"/>
                    <img src={require("assets/img/Floating/Youtube_mobile.png")} alt="" className="mobile"/>
                </a>
            </div>
        );
    }
}

export default Floating;